<template>
  <div class="expande-horizontal pa-3 pl-1 pb-0 pt-0 pr-0 fonte">
    <v-flex xs12 class="pr">
      <v-text-field
        dense
        filled
        append-icon="mdi-magnify"
        label="Faça uma busca..."
        placeholder="ex: Patrick"
        v-model="filter.search"
        hide-details
        :color="$theme.primary"
        @input="updateList"
      ></v-text-field>
    </v-flex>
    <v-spacer></v-spacer>
  </div>
</template>
<script>
export default {
  props: ["filter"],
  methods: {
    updateList() {
      this.$forceUpdate();
    }
  }
};
</script>
